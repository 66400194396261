<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Vendor</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- Name -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.brandName.$error,
                        }"
                      >
                        <label for="name">Brand Name:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="name"
                          placeholder="Enter Brand Name"
                          v-model.trim="details.brandName"
                          @input="setTouch('brandName')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.brandName.required && $v.details.brandName.$error"
                        >
                          Brand Name is required
                        </div>
                      </div>
                    </div>

                    <!--MarkDown Percentage-->
                    <div>
                      <div class="form-group col">
                        <label for="marginPercentage">Mark down % :</label>
                        <input
                          type="number"
                          class="form-control"
                          id="marginPercentage"
                          placeholder="Enter Mark down Percentage"
                          v-model.trim="details.marginPercentage"
                          @input="setTouch('marginPercentage')"
                          min="0"
                          max="99"
                        />
                        <!-- <div
                          class="validation"
                          v-if="
                            !$v.details.marginPercentage.required &&
                            !$v.details.marginPercentage.min &&
                            !$v.details.marginPercentage.max &&
                            $v.details.marginPercentage.$error
                          "
                        >
                          Mark Down Percentage is required
                        </div> -->
                      </div>
                    </div>

                    <!--Brand Code-->
                    <div>
                      <div class="form-group col">
                        <label for="identificationCode">Code (Min 3 characters required) : </label>
                        <input
                          type="text"
                          class="form-control"
                          id="code"
                          placeholder="Enter Brand Code"
                          v-model.trim="details.identificationCode"
                          @input="setTouch('identificationCode')"
                          oninput="this.value=this.value.toUpperCase()"
                        />
                        <!-- <div
                          class="validation"
                          v-if="
                            !$v.details.identificationCode.required &&
                            !$v.details.marginPercentage.minlength &&
                            !$v.details.marginPercentage.maxlength &&
                            $v.details.identificationCode.$error
                          "
                        >
                          Code is required
                        </div> -->
                      </div>
                    </div>
                    <!-- Email -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.email.$error,
                        }"
                      >
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.email.required && $v.details.email.$error"
                        >
                          Email is required
                        </div>
                      </div>
                    </div>
                    <!-- phone no -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.mobile.$error,
                        }"
                      >
                        <label for="mobile">Mobile No:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mobile No"
                          v-model.trim="details.mobile"
                          @input="setTouch('mobile')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                          Mobile No is required
                        </div>
                      </div>
                    </div>
                    <!-- logo-->
                    <div class="form-group col">
                      <div>
                        <label for="image">Logo:</label>
                        <v-file-input
                          type="file"
                          class="form-control"
                          id="logo-image"
                          v-model="details.logo"
                          placeholder="Select Image"
                          accept="image/jpeg, image/png"
                          @change="handleLogoImage"
                        ></v-file-input>
                        <div
                          class="validation"
                          v-if="!$v.details.logo.required && $v.details.logo.$error"
                        >
                          Logo Image is required
                        </div>
                      </div>
                      <div
                        class="preview-image border p-2 mt-3 ml-3"
                        v-if="this.details.logo && logoUrl"
                      >
                        <v-img :src="logoUrl" height="230" width="100%" contain></v-img>
                      </div>
                    </div>
                    <!-- button -->
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  required,
  email,
  minValue,
  maxValue,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import vendorService from '../../../services/vendorService';
// import headerCustom from '@/components/Header.vue';
// import apiservice from "@/service/apiservice";

export default {
  data() {
    return {
      details: {
        brandName: '',
        email: '',
        mobile: '',
        userType: 'Vendor',
        password: '',
        marginPercentage: '',
        identificationCode: '',
        logo: '',
      },
      submitStatus: null,
    };
  },

  validations: {
    details: {
      brandName: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      //   marginPercentage: {
      //     required,
      //     minValue: minValue(0),
      //     maxValue: maxValue(99),
      //   },
      identificationCode: {
        required,
        minLengthValue: minLength(3),
        maxLengthValue: maxLength(3),
      },
      logo: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'brandName') {
        this.$v.details.brandName.$touch();
      } else if (fields === 'email') {
        this.$v.details.email.$touch();
      } else if (fields === 'mobile') {
        this.$v.details.mobile.$touch();
      }
      //    else if (fields === 'marginPercentage') {
      //     this.$v.details.marginPercentage.$touch();
      //   }
      else if (fields === 'identificationCode') {
        this.$v.details.identificationCode.$touch();
      } else if (fields === 'logo') {
        this.$v.details.logo.$touch();
      }
    },
    // this is use for save button
    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.submitStatus = 'OK';

        const result = await vendorService.createUser(details);
        if (result.status === 200) {
          if (result.data.data === 'User Already Exists') {
            this.$store.commit('setSnackbar', {
              content: 'Email Already In Use',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else if (result.data.data === 'User Already Exists with this brand name') {
            this.$store.commit('setSnackbar', {
              content: 'Brand Name Already Exists',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else {
            //  alert('user created successfully');
            this.$store.commit('setSnackbar', {
              content: 'Submitted Successfully',
              icon: 'mdi-check-circle',
              color: 'success ',
              isVisible: true,
            });
            this.$router.push('/viewVendors');
          }
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    async handleLogoImage(file) {
      this.logoUrl = URL.createObjectURL(this.details.logo);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.logo = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    // this method use for cancel button
    resetForm() {
      // (this.details = {
      //   brandName: '',
      //   email: '',
      //   mobile: '',
      //   userType: 'Vendor',
      //   password: '',
      // }),
      this.$router.push('/viewVendors');
    },
    // async createUser(details) {
    //   console.log('result');
    //   //  this.users = [];
    //   //   this.formData.page = pageNo;
    //   //   this.formData.limit = this.perPage;
    //   //   this.formData.search = this.searchText;
    //   const result = await customerService.getAllCustomers(details);
    //   // this.users = result.data.data;
    //   //
    //   console.log('result', result);
    // },
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
